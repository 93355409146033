import React from "react";
import { Row, Col, Card, Form, FormControl, Button, FormGroup } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql, Link } from "gatsby";
import homeImg from "../images/cover.png"
import noimageImg from "../images/noimage.png"



const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />

    <Row style={{ maxWidth: `60rem` }}>
      <img src={homeImg} style={{ width: `100%`, marginBottom: `3rem` }} alt="cover" />
    </Row>

    <Row style={{ maxWidth: `45rem` }}>
      <Form method="GET" action="/recipes" style={{ margin: `auto` }}>
        <FormGroup controlId="q">
          <div className="input-group mb-3">
            <FormControl size="lg" type="text" placeholder="Search Recipes" name="q" />
            <Button variant="primary" type="submit">
              検索
            </Button>
          </div>
        </FormGroup>
      </Form>
    </Row>

    <Row>
      <Col style={{ maxWidth: `60rem`, marginTop: `2rem` }}>
        <h1>
          Recent Recipes
        </h1>
      </Col>
    </Row>
    <Row style={{ maxWidth: `60rem` }}>
      {data.allMicrocmsRecipe.edges.map(({ node }) => (
        <Col xs={12} md={4} key={node.recipeId}>
          <Card style={{ marginTop: `1rem` }}>
            <Card.Header>
              <Link to={`/recipe/${node.recipeId}`}>
                <h5>{node.title}</h5>
              </Link>
            </Card.Header>
            <Card.Body >
              <Link to={`/recipe/${node.recipeId}`}>
                <Card.Img src={node.image === null ? noimageImg : node.image.url + `?fit=crop&w=1000&h=1000&auto=compress,enhance&q=25`} alt={node.title} />
              </Link>
              <Card.Text>
                {node.tags.map(tags => (
                  <Link to={`/tag/${tags.id}`} style={{ margin: `0.5rem` }} key={tags.id}>
                    {`#` + tags.tagname}
                  </Link>
                ))}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
    <Row>
      <Col style={{ maxWidth: `60rem`, paddingTop: `3rem` }}></Col>
    </Row>
  </Layout>
);

export default IndexPage;

export const query = graphql`
query MyQuery {
  allMicrocmsRecipe(sort: {order: DESC, fields: createdAt}, limit: 6)  {
    edges {
      node {
        title
        id
        recipeId
        createdAt
        tags {
          tagname
          id
        }
        image {
          url
        }
      }
    }
  }
}
`